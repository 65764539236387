import React , { useEffect, useState } from 'react'
import { AiOutlineRight } from "react-icons/ai";

function Gallery() {
    const [imagePaths, setImagePaths] = useState([]);

    useEffect(() => {
      const importImages = async () => {
        let paths = [];
        for (let i = 1; i <= 172; i++) {
          try {
            const image = await import(`../assets/images/happy_customer/${i}.webp`);
            paths.push(image.default);
          } catch (error) {
            console.error(`Error importing image ${i}:`, error);
          }
        }
        setImagePaths(paths);
      };
  
      importImages();
      window.scrollTo(0, 0)
    }, []);  

  return (
       <main className='container-fluid main-contact'>
       <div className='row'>
           <div className='col-12 p-0'>
               <div className='contact-title'>
                 <h5 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Gallery</h5>
                 <h6 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Home <AiOutlineRight /> Gallery</h6>
               </div>
           </div>
       </div>
       <div className='row gallery-img'>
         {imagePaths.map((imagePath, index) => (
           <div key={index} className='col-xxl-4 col-xl-6 col-lg-6' data-aos="zoom-out-right" data-aos-offset="300" >
             <img src={imagePath} className='img-fluid' alt={`Gallery Image ${index}`} />
           </div>
         ))}
       </div>
     </main>
  )
}

export default Gallery
