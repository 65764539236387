import React, { useState, useEffect } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { FiPhoneCall } from 'react-icons/fi';
import { LuMapPin } from 'react-icons/lu';
import outlets_img from  '../assets/images/58.png';
import outlets_img_two from  '../assets/images/72.jpeg.png';
import img69 from '../assets/images/69.webp';
import img70 from '../assets/images/70.webp';
import img71 from '../assets/images/71.webp';
import img74 from '../assets/images/74.jpeg';
import img80 from '../assets/images/80.jpeg';
import img81 from '../assets/images/81.jpeg';
import video1 from '../assets/images/video/outlet-gallery-video-1.mp4';
import video2 from '../assets/images/video/outlet-gallery-video-2.mp4';
import video3 from '../assets/images/video/outlet-gallery-video-3.mp4';
import video4 from '../assets/images/video/4.mp4';
import video5 from '../assets/images/video/5.mp4';
import video6 from '../assets/images/video/6.mp4';

function Outlets() {
    const [activeTab, setActiveTab] = useState('images');

    useEffect(() => {
        const videos = document.querySelectorAll('.gallery video');
        videos.forEach(video => {
            video.style.display = activeTab === 'videos' ? 'block' : 'none';
        });
        window.scrollTo(0, 0)
    }, [activeTab]);

    function filter(category) {
        setActiveTab(category);
    }

    return (
        <main className='container-fluid main-contact'>
            <div className='row'>
                <div className='col-12 p-0'>
                    <div className='contact-title'>
                        <h5 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Outlets</h5>
                        <h6 data-aos="fade-right" data-aos-offset="400"
                            data-aos-easing="ease-in-sine">Home <AiOutlineRight /> Outlets</h6>
                    </div>
                </div>
            </div>
            <div className="row justify-content-between align-items-center our-outlets-box">
                <div className="col-xxl-8 col-xl-10 col-lg-10 col-md-12 mt-5 mb-5 m-auto">
                 <div className='d-flex gap-4 align-items-center box-wrap'>
                 <div className="outlets-main-box">
                <div className="outlets-header">
                    <img src={outlets_img} alt="Alternate Text" />
                </div>
                <div className="outlets-body">
                    <h4>Phonedekho Buy,Sell,Repair</h4>
                    <p>G-9, Shiv Astha Complex, Rangoli Chokdi,<br /> Kathor Road</p>
                </div>
                <div className="outlets-footer">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="call-information">
                            <a href="tel:18005723432">
                                <FiPhoneCall className='phone-icon' />Call Now
                            </a>
                        </div>
                        <div className="loaction-information">
                            <a href="#">
                                <LuMapPin className='map-icon' />Get Directions
                            </a>
                        </div>
                    </div>
                </div>
                </div>
                <div className="outlets-main-box outlets-two">
                <div className="outlets-header d-flex justify-content-center">
                    <img src={outlets_img_two} className='outlets-two-img' alt="Alternate Text" />
                </div>
                <div className="outlets-body">
                    <h4>Phonedekho Buy,Sell,Repair</h4>
                    <p className='title-two'>Asr 018, Ground floor, Astra tower
                       action area 2c, New town, Kolkata- 700156
                       under eco Park police Station.</p>
                </div>
                <div className="outlets-footer">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="call-information">
                            <a href="tel:18005723432">
                                <FiPhoneCall className='phone-icon' />Call Now
                            </a>
                        </div>
                        <div className="loaction-information">
                            <a href="#">
                                <LuMapPin className='map-icon' />Get Directions
                            </a>
                        </div>
                    </div>
                </div>
                </div>
                 </div>
                </div>
                <div className="our-outlates-gallery">
                    <h1 className="text-center pb-4">Photo & Video</h1>
                    <div className="tabs">
                        <div className={`tab ${activeTab === 'images' ? 'active' : ''}`} onClick={() => filter('images')}>Images</div>
                        <div className={`tab ${activeTab === 'videos' ? 'active' : ''}`} onClick={() => filter('videos')}>Videos</div>
                    </div>

                    <div className="gallery">
                        {activeTab === 'images' && (
                            <>
                                <img src={img69} alt="images" />
                                <img src={img70} alt="images" />
                                <img src={img71} alt="images" />
                                <img src={img74} alt="images" />
                                <img src={img80} alt="images" />
                                <img src={img81} alt="images" />
                            </>
                        )}
                        {activeTab === 'videos' && (
                            <>
                                <video width="500px" height="350px" controls>
                                    <source src={video1} type="video/mp4" />
                                </video>
                                <video width="500px" height="350px" controls>
                                    <source src={video2} type="video/mp4" />
                                </video>
                                <video width="500px" height="350px" controls>
                                    <source src={video3} type="video/mp4" />
                                </video>
                                <video width="500px" height="350px" controls>
                                    <source src={video4} type="video/mp4" />
                                </video>
                                <video width="500px" height="350px" controls>
                                    <source src={video5} type="video/mp4" />
                                </video>
                                <video width="500px" height="350px" controls>
                                    <source src={video6} type="video/mp4" />
                                </video>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Outlets;
