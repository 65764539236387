import React, { useEffect, useState } from 'react'
import { AiOutlineRight } from "react-icons/ai";
import location from  '../assets/images/18.png';
import email from  '../assets/images/19.png';
import call from  '../assets/images/20.png';
import axios from 'axios';
import AOS from 'aos';

function Contact() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState({
    success: false,
    error: false,
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Additional data fields
    const deviceType = 'web';
    const fcmToken = 'dummy-token';

    // Merging formData with additional fields
    const payload = {
      ...formData,
      deviceType,
      fcmToken
    };

    try {
      const response = await axios({
        method: 'POST',
        url: 'https://rizycosmetic-001-site3.atempurl.com//v1/customerQuery/inserCustomerQuery',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(payload),
      });

      console.log('Response data:', response.data);
      setFormStatus({
        success: true,
        error: false,
        message: 'Your request has been submitted successfully!'
      });

      // Hide the success message after 2 seconds
      setTimeout(() => {
        setFormStatus({
          success: false,
          error: false,
          message: ''
        });
      }, 2000); // 2000ms = 2 seconds

      // Reset form data after successful submission
      setFormData({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      console.error('Error:', error);
      setFormStatus({
        success: false,
        error: true,
        message: 'There was an error submitting your request. Please try again later.'
      });
    }
  };



  useEffect(() => {
    AOS.init({}); 
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <main className='container-fluid main-contact'>
       <div className='row'>
          <div className='col-12 p-0'>
            <div className='contact-title'>
                <h5 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Contact</h5>
                <h6 data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Home <AiOutlineRight /> Contact</h6>
            </div>
          </div>
       </div>
       <div className='row location-email-call'>
       <div className='d-flex justify-content-center align-items-center gap-5 flex-wrap'>

       <div className='d-flex justify-content-center align-items-center' data-aos="zoom-out-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
         <div className='contact-boxs'>
            <div className='img-box'>
            <img src={location} />
            </div>
            <h4>Location</h4>
            <p>UNIT NO. ASO-230, TOWER-SOUTH, Astra Towers, Action Area II, Newtown, Kolkata, New Town, West Bengal 700161</p>
         </div>
        </div>

        <div className='d-flex justify-content-center align-items-center' data-aos="zoom-out-right" data-aos-offset="300" data-aos-easing="ease-in-sine"  >
         <div className='contact-boxs'>
            <div className='img-box'>
            <img src={email} />
            </div>
            <h4>Email</h4>
            <p>info@acceclub.com</p>
         </div>
        </div>

        <div className='d-flex justify-content-center align-items-center' data-aos="zoom-out-right" data-aos-offset="300" data-aos-easing="ease-in-sine"  >
         <div className='contact-boxs'>
            <div className='img-box'>
            <img src={call} />
            </div>
            <h4>Call Us</h4>
            <p>18005723432</p>
         </div>
        </div>

       </div>
       </div>
       <div className="row d-flex justify-content-center">
            <div className='col-xxl-6 col-xl-6 p-50 pe-0 mt-5' data-aos="zoom-in-down" data-aos-offset="400" data-aos-easing="ease-in-sine">
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7894.420536818548!2d88.45918118771108!3d22.621052192396174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89fa843f35141%3A0xf200e4d62cb7d089!2sACCE%20GLOBAL%20SOFTWARE!5e0!3m2!1sen!2sin!4v1713335318014!5m2!1sen!2sin" width="100%" height="690" loading="lazy" ></iframe>
             </div>
              <div className="col-xxl-6 col-xl-6 get-boxs d-flex flex-column justify-content-center">
              <div className="get-touch-title text-center">
                 <h5>Get in Touch</h5>
                 <h6>Let's Discuss Your Project Today</h6>
              </div>
                <div className="get-touch mt-4 m-4">
                <div className="">
                      <form className="get-touch-form row" onSubmit={handleSubmit}>
                       <div className="col-xxl-6 col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                       </div>
               
                       <div className="col-xxl-6 col-xl-6">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
                       </div>
               
                       <div className="col-xxl-6 col-xl-6">
          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
            <input
              type="number"
              className="form-control"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
                       </div>
               
                       <div className="col-xxl-6 col-xl-6">
          <div className="mb-3">
            <label htmlFor="subject" className="form-label">Subject</label>
            <input
              type="text"
              className="form-control"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
                       </div>
               
                       <div className="col-12">
          <div className="mb-3">
            <label htmlFor="message" className="form-label">How can we help you?</label>
            <textarea
              className="form-control"
              id="message"
              rows="3"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
                       </div>
               
                       <div className="col-12 d-flex justify-content-center mt-3">
          <button className="submit-your-request" type="submit">
            Submit Your Request
          </button>
                       </div>
                      </form>

                 {/* Display Success/Failure Message */}
                 {formStatus.success && (
                   <div className="alert alert-success mt-3" role="alert">
                     {formStatus.message}
                   </div>
                 )}
                 {formStatus.error && (
                   <div className="alert alert-danger mt-3" role="alert">
                     {formStatus.message}
                   </div>
                 )}
                 </div>
            </div>
          </div>
           </div>
      </main>
    </>
  )
}

export default Contact
